:root {
  --primary: #1891F6;
  --primary-rgb: 24, 145, 246;
  --hover-state: rgba(24, 145, 246, 0.1);
  --selected-row: rgba(24, 145, 246, 0.1);
  --tooltip-background : #000000;
}
.fs-global-topbar__toolbar__listitem--dropdown {
  img {
    display: block;
    margin-right:5%
  }
}

.fs-global-topbar__toolbar__listitem--img {
  margin-right: 20px !important;
  display: block !important;
  img {
    width: 34px !important;
    height: 18px !important;
  }
}

.fs-global-topbar {
  &__logo {
    margin-top: 3px !important;
    img {
      width: 162px !important;
      height: 26px !important;
    }
  }
  &__toolbar {
    width: 33% !important;
  }
}
.fs-global-topbar__userbuliding {
  margin-left: 8px !important;
}

.about-page-img {
  width: auto !important;
}

.version-text,.copy-right-txt {
  font-size: 12px;
  color: var(--text);
  padding: 0px 40px 0px 40px;
}

.user_name {
  color: var(--primary) !important;

  & .fas {
    color: var(--white-color) !important;
  }
}